body {
  font-family: 'Press Start 2P';
  font-size: 16px;
  line-height: 1.5; 
}

#buttons {
	margin: auto;
}

#app {
  padding: 1rem;

  display: flex;
  flex-direction: column;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
